import type { ComponentProps } from "react";
import type AssetActivityChart from "../AssetActivityChart";
import type { CustomSeriesOption } from "echarts";
import { renderItem } from "./renderItem";
import type { Theme } from "@mui/joy";

type AssetActivityChartProps = ComponentProps<typeof AssetActivityChart>;

export type OperationTypes =
  | "Completion"
  | "Drilling"
  | "FormationEvaluation"
  | "Interruption"
  | "Moving"
  | "PlugAbandon"
  | "NotAvailable"
  | "Workover";

export const makeSeries = (
  wellbores: NonNullable<AssetActivityChartProps["wellbores"]>,
  operations: NonNullable<AssetActivityChartProps["operations"]>,
  colors: NonNullable<AssetActivityChartProps["colors"]>["operations"],
  operationTypeLegendNames: Record<OperationTypes, string>,
  theme: Theme
): CustomSeriesOption[] => {
  return operations.map((op) => {
    return {
      type: "custom" as const,
      name: operationTypeLegendNames[op.type] + "        ", // this controls the legend
      renderItem: (params, api) => renderItem(params, api, theme),
      itemStyle: {
        opacity: 0.8,
      },
      encode: {
        x: [1, 2],
        y: 0,
      },
      data: wellbores.map((w, index) => {
        const activity = op.activities.find((a) => a.wellboreId === w.id);

        let hasLeftNeighbor = false;
        let hasRightNeighbor = false;

        operations.forEach((op) => {
          const act = op.activities.find((a) => a.wellboreId === w.id);

          if (act && activity) {
            const activityStartDate = new Date(activity.startDate).getTime();
            const activityEndDate = new Date(activity.endDate).getTime();
            const actStartDate = new Date(act.startDate).getTime();
            const actEndDate = new Date(act.endDate).getTime();
            if (activityStartDate === actEndDate) {
              hasLeftNeighbor = true;
            }
            if (activityEndDate === actStartDate) {
              hasRightNeighbor = true;
            }
          }
        });

        return [
          index,
          activity?.startDate,
          activity?.endDate,
          op.type,
          w.name,
          colors[op.type],
          w.id,
          hasLeftNeighbor,
          hasRightNeighbor,
        ];
      }),
      z: 10,
    };
  });
};
