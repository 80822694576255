import { Box, Grid, Typography } from "@mui/joy";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";

const translationStrings = [
  "No Data Available",
  "Error on loading data",
] as const;

function ErrorChart({
  isError,
  translations,
}: {
  isError?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
}) {
  const t = {
    ...defaultTranslations(translationStrings),
    ...translations,
  };
  return (
    <Grid height={428} container justifyContent="center" alignItems="center">
      <Box>
        <Typography level="body-md" fontSize="lg" fontWeight={300}>
          {isError ? t["Error on loading data"] : t["No Data Available"]}
        </Typography>
      </Box>
    </Grid>
  );
}
export default ErrorChart;
